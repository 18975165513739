/* eslint-disable radix */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-danger */
import React from "react";
import { graphql } from "gatsby";
import Pagination from "../components/Pagination";
import CoverImageTitle from "../components/coverImageTitle";
import BlogArticle from "../components/BlogArticle";

const BlogTemplate = props => {
  const {
    data: {
      wordpressPage: {
        acf: {
          title,
          background_image: {
            localFile: {
              childImageSharp: { fluid: coverImage },
            },
          },
        },
      },
      allWordpressPost: { totalCount, edges },
    },
    pageContext,
  } = props;

  const totalPages = Math.ceil(totalCount / 4);
  return (
    <>
      <div className="blog-wrap-hero">
        <CoverImageTitle title={title} fluid={coverImage} />
      </div>
      <div className="blog-container"> 
        {edges.map(post => {
          const wpCategories = post.node.acf.dynamic_fields_post.find(item => item.__typename === 'WordPressAcf_categories');
          const categories = wpCategories.categories;
          
          return (
            <BlogArticle
              key={post.node.id}
              link={
                pageContext.lang === "en_US"
                  ? `/en/blog/${post.node.slug}/`
                  : `/blog/${post.node.slug}/`
              }
              title={post.node.title} 
              excerpt={post.node.excerpt}
              categories={categories}
              date={post.node.date}
            />)
        })}
      </div>
      <Pagination
        currPath={pageContext.lang === "en_US"}
        page={pageContext.currentPage}
        totalPages={totalPages}
      />
    </>
  );
};
 
export const query = graphql`
  query($lang: String, $skip: Int) {
    wordpressPage(slug: { eq: "blog" }, wpml_current_locale: { eq: $lang }) {
      title
      path
      yoast_head
      acf {
        title
        background_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allWordpressPost(
      filter: {title: {regex: "/^((?!DO NOT DELETE THIS POST).)*$/"}, wpml_current_locale: { eq: $lang } }
      sort: {order: DESC, fields: date}
      limit: 4
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          title
          excerpt
          link
          path
          slug
          date(formatString: "DD.MMM YYYY")
          acf {
            dynamic_fields_post {
              __typename
              ... on WordPressAcf_categories {
                id
                categories
              }
            }
          }
        }
      }
    }
  }
`;

export default BlogTemplate;
