import { Link } from 'gatsby';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const BlogArticle = ({
  link, title, excerpt, categories, date,
}) => (
  <article>
    <Link to={link}>
      <h2>{title}</h2>
    </Link>
    {ReactHtmlParser(excerpt)}
    <div className="blog-container-wrap">
      <p>{categories}</p>
      |
      <p>{date}</p>
    </div>
  </article>
);

export default BlogArticle;
