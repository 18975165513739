/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-expressions */
import { Link } from "gatsby";
import React from "react";

const Pagination = ({ totalPages, page, currPath }) => {
  const nextPage = page + 1;
  const prevPage = page - 1;

  let next = null;
  let previouse = null;

  if (currPath) {
    next = `/en/blog/${nextPage}`;
    if (prevPage === 1) {
      previouse = "/en/blog/";
    } else {
      previouse = `/en/blog/${prevPage}`;
    }
  } else {
    next = `/blog/${nextPage}`;
    if (prevPage === 1) {
      previouse = "/blog/";
    } else {
      previouse = `/blog/${prevPage}`;
    }
  }

  return (
    <div className="pagination">
      {page > 1 ? (
        <div>
          <Link to={previouse} className="pagination-btn">
            {currPath ? "PREVIOUSE" : "ZURÜCK"}
          </Link>
        </div>
      ) : (
        <div />
      )}
      <p>
        Seite {page} von {totalPages}
      </p>
      {page < totalPages ? (
        <div>
          <Link to={next} className="pagination-btn">
            {currPath ? "NEXT" : "WEITER"}
          </Link>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default Pagination;
